import React from "react"; import HomeSlider from "../Components/Home/HomeSlider.js";
import HomeOurApp from "../Components/Home/HomeOurApp.js";
import HomeServices from "../Components/Home/HomeServices.js";
import HomeTestimonials from "../Components/Home/HomeTestimonials.js";
import HomeClients from "../Components/Home/HomeClients";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

export default function Home() {
    const [isFetching, setIsFetching] = useState(true);
    useEffect(() => {
        setTimeout(function () {
          setIsFetching(false);
        }, 1000);
      }, []);
    
      if (isFetching) {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color='error' />
          </Box>
        );
      }
    return (
        <>
        
            <HomeSlider />
            <HomeOurApp />
            <HomeServices />
            <HomeTestimonials />
            <HomeClients />
        </>
    );
}
