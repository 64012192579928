import React from 'react'
import "../style/OurService.css";
import FoodDelivery from '../Components/OurServices/FoodDelivery';
import GrosseryDelivery from '../Components/OurServices/GrosseryDelivery';
import MedicineDelivery from '../Components/OurServices/MedicineDelivery';
import ParcelDelivery from '../Components/OurServices/ParcelDelivery';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
export default function OurServices() {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setIsFetching(false);
    }, 400);
  }, []);

  if (isFetching) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color='error' />
      </Box>
    );
  }
  return (
    <section>
      <div className='container-fluid px-0'>
        <div className='OurServicePage d-flex justify-content-center align-items-end'>
          <div className='container d-flex justify-content-center align-items-center' data-aos="fade-right" data-aos-duration="1000">
            <h1 className='ServiceHeading mb-4'>Our Services</h1>
          </div>
        </div>
        <FoodDelivery />
        <GrosseryDelivery />
        <MedicineDelivery />
        <ParcelDelivery />

      </div>

    </section>
  )
}
