import React from "react";
import BatoorApp from "../Components/OurApp/BatoorApp";
import BatoorRider from "../Components/OurApp/BatoorRider";
import BatoorSeller from "../Components/OurApp/BatoorSeller";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
export default function Ourapp() {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setIsFetching(false);
    }, 400);
  }, []);

  if (isFetching) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color='error' />
      </Box>
    );
  }
  return (
    <>
      <div className='container-fluid px-0'>
        <div className='OurAppPage d-flex justify-content-center align-items-end'>
          <div className='container-fluid d-flex justify-content-center align-items-center' data-aos="fade-right" data-aos-duration="1000">
            <h1 className='ServiceHeading mb-4'>Our App</h1>
          </div>
        </div>
        <BatoorApp />
        <BatoorSeller />
        <BatoorRider />
      </div>
    </>
  );
}
