import React from 'react'
import "../../style/HomeTeam.css"
export default function AboutTeam() {
    return (
        <section>
            <div className='container-fluid heropart5height position-relative px-0'>
                <header className="bg-yellow text-center py-5 mb-4">
                    <div className="container" data-aos="flip-up" data-aos-duration="1000">
                        <h1 className="fs-2 fw-bold text-dark">Meet the Team</h1>
                    </div>
                    <div className="container pt-3">
                        <div className="positioncart position-relative d-flex justify-content-center">
                            <div className="row position-absolute g-3 d-flex justify-content-center">
                                <div className="col-7 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" data-aos="flip-left" data-aos-duration="1000">
                                    <div className="card Hovercard">
                                        <div className="profile5 profilesize" />
                                        <div className="card-body  text-center">
                                            <h5 className="card-title mb-0">Sanad Khan</h5>
                                            <div className="card-text text-black-50">Founder & CEO</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" data-aos="flip-left" data-aos-duration="1000">
                                    <div className="card Hovercard">
                                        <div className="profile2 profilesize" />
                                        <div className="card-body  text-center">
                                            <h5 className="card-title mb-0">Ali Murtaza</h5>
                                            <div className="card-text text-black-50">Customer Service Representative & Data Manager</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" data-aos="flip-left" data-aos-duration="1000">
                                    <div className="card Hovercard">
                                        <div className="profile3 profilesize" />
                                        <div className="card-body  text-center">
                                            <h5 className="card-title mb-0">Mehran Ahmed</h5>
                                            <div className="card-text text-black-50">Buisness Development & Fleet Manager</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" data-aos="flip-left" data-aos-duration="1000">
                                    <div className="card Hovercard">
                                        <div className="profile4 profilesize" />
                                        <div className="card-body  text-center">
                                            <h5 className="card-title mb-0">Ahmad Faraz</h5>
                                            <div className="card-text text-black-50">Account Manager</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" data-aos="flip-left" data-aos-duration="1000">
                                    <div className="card Hovercard">
                                        <div className="profile1 profilesize" />
                                        <div className="card-body  text-center">
                                            <h5 className="card-title mb-0">Fawad Khan</h5>
                                            <div className="card-text text-black-50">Operations Manager</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </section>


    )
}
