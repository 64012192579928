import React from "react";
import "../../style/HomeClients.css"
import Slider from "react-slick";
import B1 from '../../images/B1.jpg'
import B2 from '../../images/B2.jpg'
import B3 from '../../images/B3.jpg'
import B4 from '../../images/B4.jpg'
import B5 from '../../images/B5.jpg'
import B6 from '../../images/B6.jpg'
import B7 from '../../images/B7.jpg'
import B8 from '../../images/B8.jpg'
import B9 from '../../images/B9.jpg'
import B10 from '../../images/B10.jpg'
import B11 from '../../images/B11.jpg'
import B12 from '../../images/B12.jpg'
import B13 from '../../images/B13.jpg'
import B14 from '../../images/B14.jpg'
import B15 from '../../images/B15.jpg'
import B16 from '../../images/B16.jpg'
import B17 from '../../images/B17.jpg'
import B18 from '../../images/B18.jpg'

export default function HomeClients() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: true,
        speed: 9000,
        autoplaySpeed: 100,
        focusOnSelect: true,
        pauseOnHover: false,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <section>
            <div className="container-fluid  px-0 mt-5 ClientsBackgrond">
                <div className="container ">
                    <div className="row gx-0">
                        <div className="text-center pt-5">
                            <div className="col d-flex justify-content-center" data-aos="flip-up" data-aos-duration="1000">
                                <p className="text-dark fs-5 mb-0">Clients</p>
                            </div>
                            <div className="col d-flex justify-content-center" data-aos="flip-up" data-aos-duration="1000">
                                <p className="text-dark fw-bold fs-2 ">Our Happy clients</p>
                            </div>
                            <div className="mt-4" data-aos="zoom-out" data-aos-duration="1000">
                                <Slider {...settings}>
                                    <div><img src={B1} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B2} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B3} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B4} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B5} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B6} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B7} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B8} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B9} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B10} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B11} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B12} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B13} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B14} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B15} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B16} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B17} className="w-100 pe-2" alt="logo" /></div>
                                    <div><img src={B18} className="w-100 pe-2" alt="logo" /></div>

                                </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    );
}