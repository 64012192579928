import React from 'react'
import AboutCart1 from '../../images/AboutCart1.jpg'

export default function AboutusCart() {
    return (
        <section>
            <div className='container d-flex justify-content-center'>
            <div  className="card mb-3" style={{maxWidth: "68rem"}} data-aos="fade-up" data-aos-duration="1500">
                <div  className="row g-0">
                    <div  className="col-md-4">
                        <img src={AboutCart1}  className="img-fluid rounded-start" alt="..." />
                    </div>
                    <div  className="col-md-8">
                        <div  className="card-body">
                            <h2  className="card-title text-center H2heading pt-3">Why Us</h2>
                            <p  className="card-text-para pt-3">Why bother going out when you can get everything delivered home with the tap of a button? Batoor delivery app gets you food, groceries, medicine, pet supplies, fruits and vegetables, meat, fish, health and wellness products and even send packages from one end of the city to the other. From your local neighborhood stores to your favorites brands. We are always on the move for you.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}
