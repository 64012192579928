import React from 'react'
import "../../style/HomeOurApp.css"
import img2 from "../../images/img2.png";
import playicon from "../../images/playicon.png";

export default function HomeOurApp() {
    return (
        <section>
            <div className='flow'>
                <div className='position-relative px-0 heropart2height'>
                    <div className="container-fluid headerpart2">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 pb-4' data-aos="fade-right" data-aos-duration="1500">
                                    <div className="text-center pt-5">
                                        <h2 className=" fw-bold pt-3">Download Our App Now</h2>
                                        <p>
                                            Batoor’s mission is to save consumers and businesses precious time and money and provide convenience by delivering all their needs from their local market to their doorstep on affordable prices.
                                        </p>
                                        <div className="text-center  ">
                                            <a href="https://play.google.com/store/apps/details?id=com.regexbyte.batoor&hl=en&gl=US"><img src={playicon} alt="" className="w-25" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4' data-aos="fade-left" data-aos-duration="1500">
                                    <div className='position-absolute part2image'>
                                        <img src={img2} className='imagesize' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
