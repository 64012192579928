import React from "react";
import { BsSpeedometer } from 'react-icons/bs';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { AiOutlineMobile } from 'react-icons/ai';
import { BsEmojiLaughing } from 'react-icons/bs';
import { ImUserTie } from 'react-icons/im';
import { AiOutlineEdit } from 'react-icons/ai';
import "../../style/HomeServices.css"

export default function HomeServices() {
    return (
        <section>
            <div className="position-relative heropart3height container-fluid px-0">
                <div className="headerpart3 ">
                    <div className="container ">
                        <div className="row gx-0" data-aos="flip-up" data-aos-duration="1000">
                            <div className="col d-flex justify-content-center">
                                <p className="text-dark fw-bold fs-2 pt-4">Why Choose Us</p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="text-center pt-4 position-relative">
                            <div className="row position-absolute px-0 m-0 d-flex justify-content-center">
                                <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-4 " data-aos="flip-left"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000">
                                    <div className="card cardsize shadow">
                                        <span className="fs-1 pt-3 text-danger"><BsSpeedometer /></span>
                                        <div className="card-body">
                                            <h6 className="card-title fw-bold">Speed of Delivery</h6>
                                            <p className="card-text pt-2">Speed is one thing we're known for along with efficiency we believe in delivering excellence.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-4" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                    <div className="card cardsize shadow">
                                        <span className="fs-1 pt-3 text-warning"><AiOutlineSafetyCertificate /></span>
                                        <div className="card-body">
                                            <h6 className="card-title fw-bold">Safety and Security</h6>
                                            <p className="card-text pt-2">Batoor takes a lot of careful consideration about protecting your parcels and privacy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-4" data-aos="flip-left"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000">
                                    <div className="card cardsize shadow">
                                        <span className="fs-1 pt-3 text-success"><BsEmojiLaughing /></span>
                                        <div className="card-body">
                                            <h6 className="card-title fw-bold">Reliability</h6>
                                            <p className="card-text pt-2">We believe that a service is reliable only and only if the customers are happy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-4" data-aos="flip-left"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000">
                                    <div className="card cardsize shadow">
                                        <span className="fs-1 pt-3 text-primary"><ImUserTie /></span>
                                        <div className="card-body">
                                            <h6 className="card-title fw-bold">Professionalism</h6>
                                            <p className="card-text pt-2">We believe in delivering excellence in every factor of our linked chain of services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-4" data-aos="flip-left"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000">
                                    <div className="card cardsize shadow">
                                        <span className="fs-1 pt-3 text-success"><AiOutlineEdit /></span>
                                        <div className="card-body">
                                            <h6 className="card-title fw-bold">Special Needs</h6>
                                            <p className="card-text pt-2">Batoor has a highly flexible model and its services can always be tailored as per your needs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-4" data-aos="flip-left"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="1000">
                                    <div className="card cardsize shadow">
                                        <span className="fs-1 pt-3 text-info"><AiOutlineMobile /></span>
                                        <div className="card-body">
                                            <h6 className="card-title fw-bold">Ease of placing order</h6>
                                            <p className="card-text pt-2">We have our own app, and so many specialized channels for customer order facilitation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
