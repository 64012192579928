import React from 'react'
import "../../style/OurService.css"
import medicine from "../../images/medicine.png";

export default function MedicineDelivery() {
    return (
        <section>
            <div className='MedicineHeight1 pt-2'>
                <div className='position-relative px-0 Ourserviceheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12' data-aos="fade-right" data-aos-duration="1500">
                                    <div className="text-center pt-5">
                                        <h2 className=" fw-bold pt-3">Medicine Delivery</h2>
                                        <div className='d-flex justify-content-center'>
                                            <p>No need to go to the pharmacy for getting medicine. Simply order your medicine from any pharmacy and it will be delivered directly to your doorstep</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 d-flex justify-content-center' data-aos="fade-left" data-aos-duration="1500">
                                    <div className='medicinesetting'>
                                        <img src={medicine} className='medicineimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
