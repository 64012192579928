import React from 'react'
import "../style/Gallery.css"
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

export default function Gallery() {
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        setTimeout(function () {
            setIsFetching(false);
        }, 400);
    }, []);

    if (isFetching) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color='error' />
            </Box>
        );
    }
    return (
        <section>
            <div className='container-fluid px-0'>
                <div className='galleryHeader d-flex justify-content-center align-items-end'>
                    <div className='row'>
                        <div className='col' data-aos="fade-right" data-aos-duration="1000">
                            <h1 className='ServiceHeading mb-4'>Gallery</h1>
                        </div>
                    </div>
                </div>
                <div className='container mt-5'>
                    <div className="row people d-flex justify-content-center g-4">
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-9">
                                <div className="cover">
                                    <a href="https://www.youtube.com/watch?v=ioImY2nOZz4&ab_channel=Batoor" className='text-decoration-none text-white'><h3 className='name'>Batoor on Demand Delivery Serives in Swabi and Mardan</h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-1">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid0sJ4R8j4RRQ91uf8D69SHRGAgN1D5KhbcbYCgnFmmDz1dcxzUryNY8Z9DEqvtZPphl" className='text-decoration-none text-white'><h3 className='name'>Launching ceremony in Mardan</h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-2">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid0ap3NTZz3kP9cGias6YKihnLjWCAXFPHCiocEUqXFRkLakNWGpv4G33idhjxG3Wubl" className='text-decoration-none text-white'><h3 className='name'>Batoor X Abhi collaboration</h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-3">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid02n2H2mo8uzMwpvpvkM5d4J2XVE4n1ZdvqgEeHU41wFfn4zcC2a7vJiG2WitHFHGPbl" className='text-decoration-none text-white'><h3 className='name'>Sanad Khan X National Summit Peshawar. </h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-4">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid02Ro2tjmfCMadNrTZcz1PHEpTZv9wPfhFMmGXG1s8idPtWJX2Msq7FyVhj8U62G9bCl" className='text-decoration-none text-white'><h3 className='name'>Sanad Khan X Enterprise Challenge Pakistan </h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-5">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid02XNJ5qfg6KxwbF1LWkJPbXZmHz6gybCFWGhBcEvB9k1VhtoZ3U9EBYQ2ii9x5DZvDl" className='text-decoration-none text-white'><h3 className='name'>Sanad Khan X  Batoor Mardan team </h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-6">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid0dYccHqrojnGsdXyjutmeVewdzkqNKWHUDPxsTAiTQHhpp734zqFA6dCNZ6d7Cac5l" className='text-decoration-none text-white'><h3 className='name'>Sanad Khan Mardan visit </h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-7">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid035aSg6WAs264si3W37JZxNEM5EvTimbtde5p7p9hDNGNSQNT78WBMhvay8eeQ7gr1l" className='text-decoration-none text-white'><h3 className='name'>Sanad Khan X Miss Qurat Al ain Wazir.  </h3></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 item" data-aos="flip-left" data-aos-duration="1000">
                            <div className="box G-image-8">
                                <div className="cover">
                                    <a href="https://www.facebook.com/batoorpk/posts/pfbid0dYccHqrojnGsdXyjutmeVewdzkqNKWHUDPxsTAiTQHhpp734zqFA6dCNZ6d7Cac5l" className='text-decoration-none text-white'><h3 className='name'>Sanad Khan X Bedar Zalmi foundation</h3></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}
