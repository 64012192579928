import React from 'react'
import AboutHeader from '../Components/About/AboutHeader';
import AboutCart from '../Components/About/AboutCart';
import AboutTeam from '../Components/About/AboutTeam';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import AboutUsCart2 from '../Components/About/AboutUsCart2';
import AboutusCart from '../Components/About/AboutusCart';

export default function About() {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setIsFetching(false);
    }, 400);
  }, []);

  if (isFetching) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color='error' />
      </Box>
    );
  }
  return (
    <div>
      <AboutHeader />
      <AboutusCart/>
      <AboutUsCart2/>
      <AboutCart />
      <div className='mt-5'>
        <AboutTeam />
      </div>

    </div>
  )
}
