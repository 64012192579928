import React from 'react'
import "../style/Faqs.css"
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

export default function Faqs() {
	const [isFetching, setIsFetching] = useState(true);

	useEffect(() => {
		setTimeout(function () {
			setIsFetching(false);
		}, 400);
	}, []);

	if (isFetching) {
		return (
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<CircularProgress color='error' />
			</Box>
		);
	}
	return (
		<section>
			<div className=" faqheader ">
			</div>
			<div className="container accordion accordion-flush pt-5" id="accordionFlushExample">
				<div className="accordion-item">
					<h2 className="accordion-header" id="flush-headingOne">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
							How to place order on Batoor?
						</button>
					</h2>
					<div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
						<div className="accordion-body">Download Batoor app from Google and place your order or Whatsapp: 03111900097 / 0340823420</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="flush-headingTwo">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
							What are your delivery charges?
						</button>
					</h2>
					<div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
						<div className="accordion-body">Our delivery charges are very reasonable and affordable. We are charging 10rs/km from picking point to dropping point.</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="flush-headingThree">
						<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
							What is your service timing?
						</button>
					</h2>
					<div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
						<div className="accordion-body">Batoor service timing 7 days a week 10am to 10pm.</div>
					</div>
				</div>
			</div>

		</section>
	)
}
