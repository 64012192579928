import React from 'react'
import "../../style/OurService.css"
import food from "../../images/food.png";

export default function FoodDelivery() {
    return (
        <section>
            <div className='FoodHeight1'>
                <div className='position-relative px-0 Ourserviceheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12' data-aos="fade-right" data-aos-duration="1500">
                                    <div className="text-center pt-5">
                                        <h2 className=" fw-bold pt-3">Food Delivery</h2>
                                        <div className='d-flex justify-content-center'>
                                            <p>When you are craving a burger, pizza, desi food or Guests showed up unexpectedly there is no need to worry about making an unexpected trip to your local restaurant. We will deliver what you want right to your doorstep.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 d-flex justify-content-center' data-aos="fade-left" data-aos-duration="1500">
                                    <div className='foodsetting'>
                                        <img src={food} className='foodimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
