import Navbar from './Components/Navbar/Navbar.js'
import Home from './Pages/Home.js';
import About from './Pages/About.js';
import Ourapp from './Pages/Ourapp.js';
import Gallery from './Pages/Gallery.js';
import Privacy from './Pages/Privacy.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Footer from './Components/Footer/Footer.js';
import Faqs from './Pages/Faqs.js';
import OurServices from './Pages/OurServices.js';
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/ourapp">
            <Ourapp />
          </Route>
          <Route exact path="/faqs">
            <Faqs />
          </Route>
          <Route exact path="/ourservies">
            <OurServices />
          </Route>
          <Route exact path="/gallery">
            <Gallery />
          </Route>
          <Route exact path="/privacypolicy">
            <Privacy />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>

    </>
  );
}

export default App;
