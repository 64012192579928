import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../style/HomeTestimonials.css"
import test1 from "../../images/test1.jpg"
import test2 from "../../images/test2.jpg"
import test3 from "../../images/test3.jpg"
import { Carousel } from "react-responsive-carousel";


export default function HomeTestimonials() {
    return (
        <section>
            <div className="container-fluid backcolortest px-0">
                <div className="container">
                    <div className="row px-0">
                        <div className="text-center mt-5">
                            <div className="col d-flex justify-content-center" data-aos="flip-up" data-aos-duration="1000">
                                <p className="text-dark fs-5 mb-0">Testimonials</p>
                            </div>
                            <div className="col d-flex justify-content-center" data-aos="flip-up" data-aos-duration="1000">
                                <p className="text-dark fw-bold fs-2 ">What Our Clients Say</p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 px-0" data-aos="zoom-out" data-aos-duration="1000" >
                        <Carousel
                            showArrows={false}
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            interval={2000}
                            autoFocus={false}
                            stopOnHover={false}
                        >
                            <div className='col-12'>
                                <div className="myCarousel">
                                    <img src={test1} alt="..." />
                                    <h3 className="pt-4">Mansoor Khan</h3>
                                    <p>
                                        I want to compliment BATOOR for excellent delivery service. Very Helpful, On time delivery and attentive. Thanks Batoor.
                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className=" myCarousel">
                                    <img src={test2} alt="..." />
                                    <h3 className="pt-4">Jawad Yousafzai</h3>
                                    <p>
                                        The young gang is delivering quality with in no time. Highly recommended.
                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="myCarousel">
                                    <img src={test3} alt="..." />
                                    <h3 className="pt-4">Mahgul</h3>
                                    <p>
                                        Batoor is very dedicated toward their work and customer satisfaction..... they are bringing to you all great fashion stores in swabi....and fresh food to your door steps...gudluck batoor company.
                                    </p>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    );
}