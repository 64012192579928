import React from 'react'
import "../../style/OurService.css"
import parcel from "../../images/parcel.png";

export default function ParcelDelivery() {
    return (
        <section>
            <div className='ParcelHeight1 pt-2'>
                <div className='position-relative px-0 Ourserviceheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 order2' data-aos="fade-left" data-aos-duration="1500">
                                    <div className="text-center pt-5">
                                        <h2 className=" fw-bold pt-3">Parcel Delivery</h2>
                                        <div className='d-flex justify-content-center'>
                                            <p>Send anything to your close ones from anywhere in Swabi and Mardan up to 12kg weight via Batoor. Just download the Batoor delivery app or contact us and get your courier delivered.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 order1 d-flex justify-content-center' data-aos="fade-right" data-aos-duration="1500">
                                    <div className='parcelimgseting'>
                                        <img src={parcel} className='parcelimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
