import React from 'react'
import "../../style/AboutHeader.css"
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { BsDownload } from 'react-icons/bs';
import { BsFillCartCheckFill } from 'react-icons/bs';
export default function AboutHeader() {
    return (
        <section>
            <div className='container-fluid px-0'>
                <div className='header-height '>
                    <header className="container-fluid AboutBackground position-relative d-flex justify-content-center align-items-center">
                        <div className='row position-absolute d-flex justify-content-center align-items-center'>
                            <div className='col-12' data-aos="fade-right" data-aos-duration="1000">
                                <h1 className='ServiceHeading mb-4'>About Us</h1>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className="row position-absolute Cartposition d-flex justify-content-center m-0">
                                <div className="col-12 col-lg-4 col-md-4 col-sm-5 pb-4 d-flex justify-content-center" data-aos="flip-left" data-aos-duration="2000">
                                    <div className="card aboutCardsize">
                                        <span className="fs-1 pt-3 text-center text-danger"><BsFillCartCheckFill /></span>
                                        <div className="card-body text-center">
                                            <h5 className="carttitle">35000+</h5>
                                            <h5 className="carddesc">Orders Delivered</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-4 col-sm-5 pb-4 d-flex justify-content-center" data-aos="flip-left" data-aos-duration="2000">
                                    <div className="card aboutCardsize">
                                        <span className="fs-1 pt-3 text-center text-success"><BsDownload /></span>
                                        <div className="card-body text-center">
                                            <h5 className="carttitle">6K+</h5>
                                            <h5 className="carddesc">APP Downloads</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-12 col-sm-5 d-flex justify-content-center" data-aos="flip-left" data-aos-duration="2000">
                                    <div className="card aboutCardsize">
                                        <span className="fs-1 pt-3 text-center text-primary"><MdOutlineDeliveryDining /></span>
                                        <div className="card-body text-center">
                                            <h5 className="carttitle">100+</h5>
                                            <h5 className="carddesc">Merchants onboard</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
        </section>
    )
}
