import React from 'react'
import "../../style/OurApp.css"
import Mob2 from "../../images/mob2.png";
import { BsCardChecklist } from 'react-icons/bs';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { AiOutlineMobile } from 'react-icons/ai';

export default function BatoorSeller() {
    return (
        <section>
            <div className='OurAppHeight2 pt-2'>
                <div className='position-relative px-0 Ourappheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 d-flex justify-content-center order2' data-aos="fade-left" data-aos-duration="1500">
                                    <div className="pt-5">
                                        <h2 className=" fw-bold pt-3">Batoor Seller</h2>
                                        <div className='d-flex justify-content-center'>
                                            <ul className="text-black fs-5 list-unstyled text-start">
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-primary"><HiOutlineOfficeBuilding /></span> Developed For Merchants</li>
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-success"><AiOutlineMobile /></span> Merchants can Sign-Up</li>
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-danger"><BsCardChecklist /></span> Merchants can list their Products</li>
                                            <li className='apppara'><span className="fs-4 pe-2 text-info"><BsArrowsAngleExpand /></span> Merchants can expand their Businesses</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 order1 d-flex justify-content-center' data-aos="fade-right" data-aos-duration="1500">
                                    <div className='mobimgseting'>
                                        <img src={Mob2} className='mobimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
