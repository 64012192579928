import React from 'react'
import "../../style/AboutCart.css"
import { MdLocationOn } from 'react-icons/md';
export default function AboutCart() {
    return (
        <section>
            <div className='container AboutCartHeight d-flex justify-content-center align-items-center'>
                <div className="Card-size">
                    <div className='row'>
                        <div className='col mt-4'>
                            <div className="card-body">
                                <div className='row mt-5   g-1 text-center border-top' data-aos="zoom-in">
                                    <div className='col-xxl-4 col-md-4 col-sm-12'>
                                        <div className="text-center locationHeight">
                                            <span className="fs-1 text-danger"><MdLocationOn /></span>
                                            <div className="card-body locationcartsize">
                                                <h5 className="card-title">MARDAN</h5>
                                                <p className="card-text">opposite DC office Mardan</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xxl-4 col-md-4 col-sm-12' data-aos="zoom-in">
                                        <div className="text-center locationHeight">
                                            <span className="fs-1 text-danger"><MdLocationOn /></span>
                                            <div className="card-body locationcartsize">
                                                <h5 className="card-title">NOWSHERA</h5>
                                                <p className="card-text">Nowshera Cantt</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xxl-4 col-md-4 col-sm-12' data-aos="zoom-in">
                                        <div className="text-center locationHeight">
                                            <span className="fs-1 text-danger"><MdLocationOn /></span>
                                            <div className="card-body locationcartsize">
                                                <h5 className="card-title">SWABI</h5>
                                                <p className="card-text">Main Jehangira Road Shamansoor Swabi kpk Pakistan</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
