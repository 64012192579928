import React from 'react'
import "../../style/OurService.css"
import grocery from "../../images/grocery.png";

export default function GrosseryDelivery() {
    return (
        <section>
            <div className='GroceryHeight1 pt-2'>
                <div className='position-relative px-0 Ourserviceheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 order2' data-aos="fade-left" data-aos-duration="1500">
                                    <div className="text-center pt-5">
                                        <h2 className=" fw-bold pt-3">Grossery Delivery</h2>
                                        <div className='d-flex justify-content-center'>
                                            <p>Deliver fresh vegetables, fruits, chicken, meat, and frozen foods to your doorstep in less than 30 minutes to save precious time and make your lives easier and more convenient.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 order1 d-flex justify-content-center' data-aos="fade-right" data-aos-duration="1500">
                                    <div className='grocerysetting'>
                                        <img src={grocery} className='groceryimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
