import React from 'react'
import "../../style/OurApp.css"
import Mob3 from "../../images/mob3.png";
import { AiOutlineMobile } from 'react-icons/ai';
import { TfiMapAlt } from 'react-icons/tfi';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';

export default function BatoorRider() {
    return (
        <section>
            <div className='OurAppHeight3 pt-2'>
                <div className='position-relative px-0 Ourappheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 d-flex justify-content-center' data-aos="fade-right" data-aos-duration="1500">
                                    <div className=" pt-5">
                                        <h2 className=" fw-bold pt-3">Batoor Rider</h2>
                                        <ul className="text-black fs-5 list-unstyled text-start">
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-primary"><AiOutlineMobile /></span> Chat With Customers</li>
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-danger"><TfiMapAlt /></span> Track Deliveries</li>
                                            <li className='apppara'><span className="fs-4 pe-2 text-success"><AiOutlineSafetyCertificate /></span> Safety, Security Confidentiality</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 d-flex justify-content-center' data-aos="fade-left" data-aos-duration="1500">
                                    <div className='mobimgseting'>
                                        <img src={Mob3} className='mobimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
