import React from 'react'
import hero1 from '../../images/hero-img1.jpg'
import hero2 from '../../images/hero-img2.jpg'
import hero3 from '../../images/hero-img3.jpg'
import hero4 from '../../images/hero-img4.jpg'
import Slider from "react-slick";
import '../../style/HomeSlider.css'

export default function HomeSlider() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        focusOnSelect:true,
        pauseOnHover:false,
        speed: 3000,
        autoplaySpeed: 5000,
      };
    return (
        <section>
            <div className='container-fluid px-0 SliderHeight'>
            <Slider {...settings}>
                    
                        <div>
                            <img src={hero1} alt="hero-1" className=" h-100 w-100 " />
                        </div>
                        <div>
                            <img src={hero2} alt="hero-2" className=" h-100 w-100 " />
                        </div>
                        <div>
                            <img src={hero3} alt="hero-3" className=" h-100 w-100 " />
                        </div>
                        <div>
                            <img src={hero4} alt="hero-4" className=" h-100 w-100 " />
                        </div>
                        </Slider>
            </div>
        </section>
    )
}

