import React from 'react'
import { BsTwitter } from 'react-icons/bs';
import { SiFacebook } from 'react-icons/si';
import { BsInstagram } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlinePhone } from 'react-icons/hi';
import { BsLinkedin } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { Link } from "react-router-dom";

import logo from '../../images/logo.png';
import '../../style/Footer.css';

export default function Footer() {
    return (
        <>
            <footer className="text-center text-muted mt-5" data-aos="fade-up"
                data-aos-duration="300">
                <section className="topfooter">
                    <div className="container d-flex justify-content-center justify-content-lg-between p-4">
                        <div className="me-5 d-none d-lg-block">
                            <span>Get connected with us on social networks:</span>
                        </div>
                        <div>
                            <a className="text-primary m-2" aria-current="page" href="https://www.facebook.com/batoorpk"><span className='fs-5'><SiFacebook /></span></a>
                            <a className="text-danger m-2" href="https://www.instagram.com/batoorpk/"><span className='fs-5'><BsInstagram /></span></a>
                            <a className="text-primary m-2" aria-current="page" href="https://www.linkedin.com/company/batoorpk/"><span className='fs-5'><BsLinkedin /></span></a>
                            <a className="text-success m-2" href="https://wa.me/03111900097"><span className='fs-5'><BsWhatsapp /></span></a>
                            <a className="text-dark m-2" href="https://www.tiktok.com/@batoorpk?lang=en"><span className='fs-5'><FaTiktok /></span></a>
                            <a className="text-primary m-2" aria-current="page" href="https://twitter.com/batoorpk"><span className='fs-5'><BsTwitter /></span></a>
                            <a className="text-danger m-2" aria-current="page" href="https://www.youtube.com/@batoor9365"><span className='fs-5'><BsYoutube /></span></a>
                        </div>
                    </div>

                </section>
                <section className="middlefooter pt-1 text-start">
                    <div className="container text-md-start mt-5">
                        <div className="row  ">
                            <div className="col-md-3 col-lg-4 col-xl-3  ">
                                <h6 className="text-uppercase fw-bold ">
                                    <img src={logo} alt="logo" width="200" height="100" />
                                </h6>

                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <Link to="/" className="text-reset text-decoration-none"><span className='fs-5 pe-1'><MdKeyboardArrowRight /></span>Home</Link>
                                </p>
                                <p>
                                    <Link to="/faqs" className="text-reset text-decoration-none"><span className='fs-5 pe-1'><MdKeyboardArrowRight /></span>Faqs</Link>
                                </p>
                                <p>
                                    <Link to="/privacypolicy" className="text-reset text-decoration-none"><span className='fs-5 pe-1'><MdKeyboardArrowRight /></span>Privacy policy</Link>
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Company
                                </h6>
                                <p>
                                    <Link to="/about" className="text-reset text-decoration-none"><span className='fs-5 pe-1'><MdKeyboardArrowRight /></span>About us</Link>
                                </p>
                                <p>
                                    <Link to="/ourservies" className="text-reset text-decoration-none"><span className='fs-5 pe-1'><MdKeyboardArrowRight /></span>Our Services</Link>
                                </p>
                                <p>
                                    <Link to="/gallery" className="text-reset text-decoration-none"><span className='fs-5 pe-1'><MdKeyboardArrowRight /></span>Gallery</Link>
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                                <p> <i className="fas fa-home me-3"></i>Shamansoor Swabi, Pakistan </p>
                                <p>
                                    <a className=" nav-link" href="tel:+923041110179"><span className='fs-5 pe-2'><HiOutlinePhone/></span> 0311 1900 097 </a>

                                </p>
                                <a className=" nav-link" href="mailto:info@batoor.pk"><span className='fs-5 pe-2'><AiOutlineMail /></span> info@batoor.pk </a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-4 bottomfooter">
                    © 2022 Copyright:
                    <a className="text-reset text-decoration-none fw-bold ps-2" href="https://www.batoor.pk/">Batoor.pk</a>
                    <br/>
                    Designed & Developed by
                     <a href="https://cybersynctech.com/" className='text-white text-reset text-decoration-none fw-bold'> Cyber Sync Technologies </a>
                </div>
            </footer>
        </>
    )
}
