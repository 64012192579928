import React from 'react'
import "../../style/OurApp.css"
import Mob from "../../images/mob.png";
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { TfiMapAlt } from 'react-icons/tfi';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { BiTime } from 'react-icons/bi';

export default function BatoorApp() {
    return (
        <section>
            <div className='OurAppHeight1'>
                <div className='position-relative px-0 Ourappheader mt-5'>
                    <div className="container-fluid">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 d-flex justify-content-center' data-aos="fade-right" data-aos-duration="1500">
                                    <div className="text-center pt-5">
                                        <h2 className=" fw-bold pt-3">Batoor-On Demand Delivery</h2>

                                        <ul className="text-black text-start fs-5 list-unstyled text-start">
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-primary"><MdOutlineDeliveryDining /></span> End to End Facilitation</li>
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-danger"><TfiMapAlt /></span> Track Your Order</li>
                                            <li className='apppara mb-2'><span className="fs-4 pe-2 text-success"><AiOutlineSafetyCertificate /></span> Safety, Security Confidentiality</li>
                                            <li className='apppara'><span className="fs-4 pe-2 text-info"><BiTime /></span> Lightning-Fast Delivery</li>
                                        </ul>

                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4 d-flex justify-content-center' data-aos="fade-left" data-aos-duration="1500">
                                    <div className='mobimgseting'>
                                        <img src={Mob} className='mobimg' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
