import React from 'react'
import "../../style/AboutUsCart2.css"
import About from "../../images/About.png";

export default function AboutUsCart2() {
    return (
        <section>
            <div className='flow'>
                <div className='position-relative px-0 Aboutpart2height'>
                    <div className="container-fluid headerpart2">
                        <div className="container ">
                            <div className="row justify-content-between gx-0">
                                <div className='col-12 col-xl-6 col-lg-5 col-md-12 pb-4' data-aos="fade-right" data-aos-duration="1500">
                                    <div className="text-center pt-3">
                                        <h2 className="card-title text-center H2heading pt-3">We're leading On Demand Delivery Service</h2>
                                        <p className="card-text-para pt-3">Batoor is a delivery services that connects customers with local businesses. We are dedicated to empowering the communities we serve to do more, feel better, and live happier lives by sending/receiving anything, anytime, anywhere within an hour or scheduled time.
                                            We offer fresh, fast, convenient and affordable delivery service. We play a bridge role between the consumer and businesses. Batoor is a B2B and B2C service which benefits both.
                                        </p>
                                    </div>
                                </div>
                                <div className='position-relative col-12 col-xl-6 col-lg-6 col-md-12 pb-4' data-aos="fade-left" data-aos-duration="1500">
                                    <div className='position-absolute Aboutpart2image'>
                                        <img src={About} className='Aboutimagesize' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
