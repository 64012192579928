import React, { useEffect } from 'react'
import logo from '../../images/logo.png';
import '../../style/navbar.css';
import { BsTwitter } from 'react-icons/bs';
import { SiFacebook } from 'react-icons/si';
import { BsInstagram } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import { HiOutlinePhone } from 'react-icons/hi';
import { AiOutlineMail } from 'react-icons/ai';
import { BsLinkedin } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
    let location = useLocation();
    useEffect(() => { }, [location]);
    return (
        <section>
            <div className='container-fluid bg-dark1'>
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg topnav">
                        <a className="navbar-brand pb-0 pt-0 d-flex" href="/">
                            <img src={logo} alt="logo" width="150" height="75" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="container">
                            <div className="collapse navbar-collapse d-none" id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">

                                </ul>
                                <div className=" navbar-nav d-flex flex-row align-items-center" role="search">
                                    <li className="nav-item pe-2 mt-2">
                                        <a className=" nav-link" href="mailto:info@batoor.pk"><span className='fs-5 pe-2'><AiOutlineMail /></span> info@batoor.pk </a>
                                    </li>
                                    <li className="nav-item pe-4 mt-2">
                                        <a className=" nav-link" href="tel:+923041110179"><span className='fs-5 pe-2'><HiOutlinePhone /></span> 0311 1900 097 </a>
                                    </li>
                                    <li className="nav-item pe-3 mt-2">
                                        <a className="text-primary" aria-current="page" href="https://www.facebook.com/batoorpk"><span className='fs-5'><SiFacebook /></span></a>
                                    </li>
                                    <li className="nav-item pe-3 mt-2">
                                        <a className="text-danger" href="https://www.instagram.com/batoorpk/"><span className='fs-5'><BsInstagram /></span></a>
                                    </li>
                                    <li className="nav-item pe-3 mt-2">
                                        <a className="text-primary" aria-current="page" href="https://www.linkedin.com/company/batoorpk/"><span className='fs-5'><BsLinkedin /></span></a>
                                    </li>
                                    <li className="nav-item pe-3 mt-2">
                                        <a className="text-success" href="https://wa.me/03111900097"><span className='fs-5'><BsWhatsapp /></span></a>
                                    </li>
                                    <li className="nav-item pe-3 mt-2">
                                        <a className="text-dark" href="https://www.tiktok.com/@batoorpk?lang=en"><span className='fs-5'><FaTiktok /></span></a>
                                    </li>
                                    <li className="nav-item pe-3 mt-2">
                                        <a className="text-primary" aria-current="page" href="https://twitter.com/batoorpk"><span className='fs-5'><BsTwitter /></span></a>
                                    </li>
                                    <li className="nav-item mt-2">
                                        <a className="text-danger" aria-current="page" href="https://www.youtube.com/@batoor9365"><span className='fs-5'><BsYoutube /></span></a>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="container-fluid bg-dark2 position-relative">
                <nav className="navbar navbar-expand-lg  p-0">
                    <div className="container">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className=" navbar-nav mx-auto mb-2 mb-lg-0 text-center p-2">
                                <li className=" nav-item pe-2">
                                    <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to="/">Home </Link>
                                </li>
                                <li className="nav-item pe-2">
                                    <Link className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">About Us</Link>
                                </li>
                                <li className="nav-item pe-2">
                                    <Link className={`nav-link ${location.pathname === "/ourapp" ? "active" : ""}`} to="/ourapp">Our App</Link>
                                </li>
                                <li className="nav-item pe-2">
                                    <Link className={`nav-link ${location.pathname === "/ourservies" ? "active" : ""}`} to="/ourservies">Our Services</Link>
                                </li>
                                <li className="nav-item pe-2">
                                    <Link className={`nav-link ${location.pathname === "/gallery" ? "active" : ""}`} to="/gallery">Gallery</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </section>
    )
}
